// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-service-jsx": () => import("./../src/templates/service.jsx" /* webpackChunkName: "component---src-templates-service-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-hiring-jsx": () => import("./../src/pages/hiring.jsx" /* webpackChunkName: "component---src-pages-hiring-jsx" */),
  "component---src-pages-index-back-jsx": () => import("./../src/pages/index-back.jsx" /* webpackChunkName: "component---src-pages-index-back-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landing-jsx": () => import("./../src/pages/landing.jsx" /* webpackChunkName: "component---src-pages-landing-jsx" */),
  "component---src-pages-services-jsx": () => import("./../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-team-jsx": () => import("./../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-work-jsx": () => import("./../src/pages/work.jsx" /* webpackChunkName: "component---src-pages-work-jsx" */)
}

